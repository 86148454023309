import { CategoryKind, MakerWithCategoriesResponse } from "@/web-client";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { FC } from "react";

interface Props {
  maker: MakerWithCategoriesResponse;
}

const MakerWithCategoriesItem: FC<Props> = ({ maker }) => {
  return (
    <article className="grid grid-cols-2 gap-24">
      <Link
        className={clsx(
          "grid aspect-4/3 bg-white shadow-md",
          // メーカーのロゴの各辺の余白ができるようにする
          "p-16",
        )}
        href={`/makers/${maker.id}`}
      >
        <div className="relative">
          <Image
            className="object-contain object-center"
            src={
              maker.logo
                ? maker.logo.urls.small
                : "/imgs/default_maker_icon.png"
            }
            alt={maker.name}
            fill
            unoptimized
          />
        </div>
      </Link>

      <div className="flex flex-col justify-between space-y-8">
        <div className="space-y-8">
          <h2 className="truncate font-bold text-sm">{maker.name}</h2>
          {maker.description && (
            <p className="line-clamp-2 text-xs">{maker.description}</p>
          )}
        </div>
        <dl>
          <dt className="sr-only">カテゴリ</dt>
          <dd>
            <div className="flex flex-col items-start gap-8">
              {maker.categories.slice(0, 2).map((category) => (
                <Link
                  key={category.id}
                  className="bg-gray-50 rounded-full overflow-hidden text-tiny text-secondary py-4 px-8 leading-none truncate"
                  href={`/makers/${maker.id}/${category.kind === CategoryKind.BUILDING_MATERIAL ? "building_materials" : "interiors"}?category_id=${category.id}`}
                >
                  {category.name}
                </Link>
              ))}
            </div>
          </dd>
        </dl>
      </div>
    </article>
  );
};
export { MakerWithCategoriesItem };
