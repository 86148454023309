import { LoadingPlaceholder } from "@/components/LoadingPlaceholder";
import { useGetTeamsByIds } from "@/features/team/api/getTeamsByIds";
import TeamItem from "@/features/team/components/TeamItem";
import { MaxWidthContainer } from "@/features/top_section/components/MaxWidthContainer";
import useInViewOnce from "@/hooks/useInViewOnce";
import { TeamResponse, TopSectionPickupContentResponse } from "@/web-client";
import { FC } from "react";
import ChunkedSwiper from "@/components/ChunkedSwiper";

interface Props {
  content: TopSectionPickupContentResponse;
  index: number;
}

const PickupTeamsSection: FC<Props> = ({ content, index }) => {
  const { inViewOnce, ref } = useInViewOnce();
  const { data: teams, error } = useGetTeamsByIds(
    inViewOnce ? `/sections/${content.id}/pickup_teams` : null,
    content.entities.map((e) => e.entity_id).join(","),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return (
    <MaxWidthContainer ref={ref}>
      {!teams ? (
        <LoadingPlaceholder
          className="aspect-[375/88] laptop:aspect-[1200/192]"
          error={!!error}
        />
      ) : (
        <ChunkedSwiper<TeamResponse>
          id={`swiper_teams_${index}`}
          items={teams}
          render={(team) => <TeamItem key={team.id} team={team} />}
          swiperClassName="small:!px-16"
        />
      )}
    </MaxWidthContainer>
  );
};
export default PickupTeamsSection;
