import {
  DefaultApiProjectsSearchGetRequest,
  ProjectResponse,
  ProjectsResponse,
} from "@/web-client/api";
import client from "@/utils/api/client";
import { AxiosResponse } from "axios";
import usePagination, { PaginationResponse } from "@/hooks/usePagination";
import { SWRInfiniteConfiguration } from "swr/dist/infinite";

export const getProjects = (
  request: DefaultApiProjectsSearchGetRequest,
): Promise<AxiosResponse<ProjectsResponse>> =>
  client.projectsSearchGet(request);

export const useGetProjects = (
  key: string | null,
  request: DefaultApiProjectsSearchGetRequest,
  config: SWRInfiniteConfiguration<ProjectResponse> = {},
): PaginationResponse<ProjectResponse> => {
  const pagination = usePagination<ProjectResponse>(
    key,
    request.limit,
    async ({ limit, page }) => {
      const { data } = await getProjects({
        ...request,
        limit,
        offset: limit * (page - 1),
      });

      return data;
    },
    { waitForAuth: true },
    config,
  );

  return pagination;
};
