import clsx from "clsx";
import { CSSProperties, FC } from "react";

interface Props {
  error: boolean;

  errorMessage?: string;
  className?: string;
  style?: CSSProperties;
}

const LoadingPlaceholder: FC<Props> = ({
  className,
  style,
  error,
  errorMessage = "読み込みに失敗しました",
}) => {
  return (
    <div
      className={clsx(
        "grid place-items-center place-content-center",
        className,
      )}
      style={style}
    >
      {error ? (
        <p className="text-sm text-tertiary font-bold">{errorMessage}</p>
      ) : (
        <img src={"/progress.gif"} alt={"読み込み中"} />
      )}
    </div>
  );
};
export { LoadingPlaceholder };
