import { TitleSection } from "@/features/top_section/components/TitleSection";
import { TopSectionTitleContentEntity } from "@/web-client";
import { FC } from "react";

const SubTitleSection: FC<
  Pick<TopSectionTitleContentEntity, "title" | "link_url">
> = ({ title, link_url }) => {
  return (
    <TitleSection
      title={title}
      link_url={link_url}
      titleClassName="laptop:text-base text-sm"
    />
  );
};

export { SubTitleSection };
