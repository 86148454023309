import clsx from "clsx";
import { PropsWithChildren, forwardRef } from "react";

interface Props {
  wrapperClassName?: string;
}

const MaxWidthContainer = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  function MaxWidthContainer({ wrapperClassName, children }, ref) {
    return (
      <div ref={ref} className={clsx(wrapperClassName, "laptop:px-40")}>
        <div className="max-w-[1600px] mx-auto">{children}</div>
      </div>
    );
  },
);

export { MaxWidthContainer };
