import { ListResponse } from "@/web-client/api";
import Link from "next/link";
import { FC, useMemo } from "react";
import { AvatarSize } from "@/components/Avatar";
import AvatarWithLabel from "@/components/AvatarWithLabel";
import { theme } from "tailwind.config";
import clsx from "clsx";

interface Props {
  list: ListResponse;
  hasUserIcon?: boolean;
}

const ListItem: FC<Props> = ({ list, hasUserIcon }): JSX.Element => {
  const hasImage = useMemo<boolean>(() => {
    return list.item_images.length > 0;
  }, [list]);

  const hasMultipleImages = useMemo<boolean>(() => {
    return list.item_images.length > 1;
  }, [list]);

  const imageClass = useMemo<string[]>(() => {
    return hasMultipleImages ? ["multiple"] : [];
  }, [hasMultipleImages]);

  const listOwner = useMemo<{
    imgSrc?: string;
    href?: string;
    name?: string;
  }>(() => {
    if (list.maker) {
      return {
        name: list.maker.name,
        imgSrc: list.maker.icon
          ? list.maker.icon.urls.small
          : "/imgs/default_maker_icon.png",
        href: `/makers/${list.maker.id}`,
      };
    }

    return {
      imgSrc: list.user?.icon.urls.small,
      name: list.user?.screen_name,
      href: `/users/${list.user?.id}/lists`,
    };
  }, [list]);

  return (
    <article
      className={clsx(
        "ListItem", // TODO: scssを消したい
        "relative transition-all grid rounded-xs overflow-hidden leading-snug gap-12",
        "group/list-item",
      )}
    >
      <div
        style={{ backgroundColor: theme.colors.gray[100] }}
        className={clsx(
          "relative w-full oveflow-hidden rounded-xs",
          "laptop:h-[240px] h-[200px]",
        )}
      >
        {hasImage && (
          <div
            className={clsx(
              "ListItem__imgs", // TODO: scssを消したい
              "absolute w-full h-full inset-0 overflow-hidden",
              imageClass.join(" "),
              "group-hover/list-item:brightness-90 transition-all",
            )}
          >
            {list.item_images.map((image, index) => {
              return (
                <div
                  key={index}
                  className="block w-full h-full bg-gray-100 bg-cover bg-center bg-no-repeat"
                  style={{ backgroundImage: `url(${image.urls.small})` }}
                />
              );
            })}
          </div>
        )}
        <Link
          href={`/lists/${list.id}`}
          prefetch={false}
          className="absolute intset-0 w-full h-full"
        />
      </div>

      <div className="space-y-8 break-all">
        <h1 className="text-sm font-bold">
          <Link
            href={`/lists/${list.id}`}
            prefetch={false}
            className="flex space-x-8 items-center"
          >
            {list.is_private && <i className="Icon icon-Lock" />}
            <span>{list.name}</span>
          </Link>
        </h1>
        {hasUserIcon && (
          <AvatarWithLabel
            {...listOwner}
            imgSrc={listOwner.imgSrc || "/imgs/default_user_icon.png"}
            size={AvatarSize.Small}
            hasName
          />
        )}
      </div>
    </article>
  );
};

ListItem.defaultProps = {
  hasUserIcon: true,
};

export default ListItem;
