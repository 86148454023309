import client from "@/utils/api/client";
import { LatestAndRandomMakersResponse } from "@/web-client";
import { AxiosResponse } from "axios";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";

export const getLatestAndRandomMakers = (): Promise<
  AxiosResponse<LatestAndRandomMakersResponse>
> => client.makersLatestAndRandomGet();

export const useGetLatestAndRandomMakers = (
  key: string | null,
  config?: SWRConfiguration,
): SWRResponse<LatestAndRandomMakersResponse> => {
  const data = useSWR(
    key,
    async () => {
      const { data } = await getLatestAndRandomMakers();

      return data;
    },
    config,
  );

  return data;
};
