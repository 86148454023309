import { format } from "date-fns";
import ja from "date-fns/locale/ja";

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return "Invalid Date"; // 無効な日付の場合の処理
  }

  return format(date, "yyyy/MM/dd", { locale: ja });
};
