import { BoardResponse } from "@/web-client/api";
import clsx from "clsx";
import Link from "next/link";
import { FC } from "react";

type Props = {
  board: BoardResponse;
  dark?: boolean;
};

const BoardItem: FC<Props> = ({ board, dark = false }) => {
  return (
    <article
      className={clsx(
        "relative p-16 whitespace-normal",
        dark ? "bg-black text-white" : "bg-white text-black",
        "group/board-item",
      )}
    >
      <div className="grid grid-cols-[80px_1fr] laptop:grid-cols-[minmax(80px,2fr)_minmax(0,3fr)] items-start gap-12 laptop:gap-24">
        <img
          className={clsx(
            "block aspect-square object-cover bg-gray-100",
            "group-hover/board-item:brightness-90 transition-all",
          )}
          src={board.upload_image.urls.small}
          alt={board.title}
        />
        <div className="space-y-8">
          <h2 className="font-bold line-clamp-2">{board.title}</h2>
          <p
            className={clsx(
              "text-xs line-clamp-3 tracking-wider leading-loose",
              dark ? "text-gray-50" : "text-secondary",
            )}
          >
            {board.description}
          </p>
        </div>
      </div>

      <Link href={`/boards/${board.id}`} className="absolute inset-0" />
    </article>
  );
};
export default BoardItem;
