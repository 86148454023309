import { PostResponse } from "@/web-client/api";
import client from "@/utils/api/client";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";

const usePostsByIds = (
  key: string | null,
  ids: string,
  config?: SWRConfiguration,
): SWRResponse<PostResponse[]> => {
  const response = useSWR(
    key,
    async () => {
      const { data } = await client.postsSearchByIDsGet({
        ids,
      });

      return data;
    },
    config,
  );

  return response;
};
export default usePostsByIds;
