import { FC } from "react";
import {
  ProductVariationWithProductResponse,
  TopSectionPickupContentResponse,
} from "@/web-client";
import { SwiperSlide } from "swiper/react";
import BasicSwiper from "@/components/BasicSwiper";
import "swiper/css";
import { useGetProductVariationsByIds } from "@/features/product_variation/api/getProductVariationsByIds";
import ProductVariationItem from "@/features/product_variation/components/ProductVariationItem";
import { MaxWidthContainer } from "@/features/top_section/components/MaxWidthContainer";
import useArrayMutation from "@/hooks/useArrayMutation";
import { LoadingPlaceholder } from "@/components/LoadingPlaceholder";
import useInViewOnce from "@/hooks/useInViewOnce";

interface Props {
  content: TopSectionPickupContentResponse;
  index: number;
}

const PickupProductVariationsSection: FC<Props> = ({ content, index }) => {
  const { inViewOnce, ref } = useInViewOnce();
  const {
    data: product_variations,
    error,
    mutate,
  } = useGetProductVariationsByIds(
    inViewOnce ? `/sections/${content.id}/pickup_product_variations` : null,
    content.entities.map((e) => e.entity_id).join(","),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
  const { update } = useArrayMutation<ProductVariationWithProductResponse>();

  return (
    <MaxWidthContainer ref={ref}>
      {!product_variations ? (
        <LoadingPlaceholder
          className="aspect-[375/305] laptop:aspect-[1200/310]"
          error={!!error}
        />
      ) : (
        <BasicSwiper
          id={`pickup_product_variations_${index}`}
          swiperProps={{
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 24,
            className: "small:!px-16",
            breakpoints: {
              800: {
                slidesPerView: 3,
                slidesPerGroup: 3,
              },
              960: {
                slidesPerView: 4,
                slidesPerGroup: 4,
              },
              1280: {
                slidesPerView: 5,
                slidesPerGroup: 5,
              },
            },
          }}
        >
          {product_variations.map((product_variation) => (
            <SwiperSlide key={product_variation.id}>
              <ProductVariationItem
                productVariation={product_variation}
                mutate={(updatedProductVariation) => {
                  mutate(update(product_variations, updatedProductVariation));
                }}
              />
            </SwiperSlide>
          ))}
        </BasicSwiper>
      )}
    </MaxWidthContainer>
  );
};

export default PickupProductVariationsSection;
