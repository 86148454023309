import useFetch from "@/hooks/useFetch";
import client from "@/utils/api/client";
import {
  DefaultApiProductVariationsSearchByIDsGetRequest,
  ProductVariationWithProductResponse,
} from "@/web-client";
import { AxiosResponse } from "axios";
import { SWRConfiguration } from "swr";

export const getProductVariationsByIds = (
  request: DefaultApiProductVariationsSearchByIDsGetRequest,
): Promise<AxiosResponse<ProductVariationWithProductResponse[]>> =>
  client.productVariationsSearchByIDsGet(request);

export const useGetProductVariationsByIds = (
  key: string,
  ids: string,
  config?: SWRConfiguration,
) => {
  const response = useFetch(
    key,
    async () => {
      const { data } = await getProductVariationsByIds({
        ids,
      });

      return data;
    },
    undefined,
    { waitForAuth: true },
    config,
  );

  return response;
};
