import { LoadingPlaceholder } from "@/components/LoadingPlaceholder";
import { useGetLatestAndRandomMakers } from "@/features/maker/api/getLatestAndRandomMakers";
import { MakerWithCategoriesItem } from "@/features/maker/components/MakerWithCategoriesItem";
import { MaxWidthContainer } from "@/features/top_section/components/MaxWidthContainer";
import BasicSwiper from "@/components/BasicSwiper";
import useInViewOnce from "@/hooks/useInViewOnce";
import { MakerResponse } from "@/web-client";
import Image from "next/image";
import Link from "next/link";
import { FC, useMemo } from "react";
import { SwiperSlide } from "swiper/react";

interface Props {
  index: number;
}

const LatestAndRandomMakersSection: FC<Props> = ({ index }) => {
  const { inViewOnce, ref } = useInViewOnce();
  const { data, error } = useGetLatestAndRandomMakers(
    inViewOnce ? "/latest_and_random_makers" : null,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  // ランダムに並び替えられた配列を作成
  const shuffledLatestMakers = useMemo(() => {
    if (!data?.latest_makers) return [];

    return data.latest_makers.sort(() => Math.random() - 0.5);
  }, [data]);

  return (
    <MaxWidthContainer ref={ref}>
      {!data ? (
        <LoadingPlaceholder
          className="aspect-[375/235] laptop:aspect-[1200/226]"
          error={!!error}
        />
      ) : (
        <>
          <div className="space-y-16">
            {data.latest_makers && (
              <BasicSwiper
                id="maker"
                swiperProps={{
                  slidesPerView: 1,
                  slidesPerGroup: 1,
                  spaceBetween: 24,
                  className: "!py-16 !px-8 small:!px-16", // ドロップシャドウが見えるようにするためのpadding
                  breakpoints: {
                    960: {
                      slidesPerView: 2,
                      slidesPerGroup: 2,
                    },
                    1280: {
                      slidesPerView: 3,
                      slidesPerGroup: 3,
                    },
                  },
                }}
              >
                {shuffledLatestMakers.map((maker) => (
                  <SwiperSlide key={maker.id}>
                    <MakerWithCategoriesItem maker={maker} />
                  </SwiperSlide>
                ))}
              </BasicSwiper>
            )}

            {data.random_makers && (
              <BasicSwiper
                id={`maker_swiper_${index}`}
                swiperProps={{
                  slidesPerView: 3.5,
                  freeMode: true,
                  className: "small:!px-16",
                  spaceBetween: 24,
                  breakpoints: {
                    800: {
                      slidesPerView: 6,
                    },
                    960: {
                      slidesPerView: 10,
                    },
                    1280: {
                      slidesPerView: 12,
                    },
                  },
                }}
              >
                {data.random_makers.map((maker) => (
                  <SwiperSlide key={maker.id}>
                    <SmallLogo maker={maker} />
                  </SwiperSlide>
                ))}
              </BasicSwiper>
            )}
          </div>
        </>
      )}
    </MaxWidthContainer>
  );
};
export default LatestAndRandomMakersSection;

interface SmallLogoProps {
  maker: MakerResponse;
}

const SmallLogo: FC<SmallLogoProps> = ({ maker }) => {
  return (
    <Link className="block relative aspect-video" href={`/makers/${maker.id}`}>
      <Image
        className="object-contain object-center"
        src={maker.logo?.urls.small}
        alt={maker.name}
        fill
        unoptimized
      />
    </Link>
  );
};
