import { formatTopBannerKind } from "@/features/top_banner/utils/formatTopBannerKind";
import { useCheckInternalUrl } from "@/hooks/useCheckInternalUrl";
import { TopBannerResponse } from "@/web-client";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { FC } from "react";

interface Props {
  banner: TopBannerResponse;
}

const TopBannerItem: FC<Props> = ({ banner }) => {
  const { isValid, internal, path } = useCheckInternalUrl(banner.link_url);

  if (!isValid) return null;

  return (
    <article className={clsx("relative grid gap-8", "group/top-banner-item")}>
      <Link
        className="relative block aspect-video rounded-xs overflow-hidden"
        href={path}
        target={internal ? "_self" : "_blank"}
      >
        <Image
          className={clsx(
            "object-cover object-center",
            "group-hover/top-banner-item:brightness-90 transition-all",
          )}
          src={banner.upload_image.urls.small}
          alt={banner.title}
          unoptimized
          fill
        />
      </Link>

      <div className="inline-flex items-center justify-center border text-[8px] rounded-full pt-4 pb-[3px] px-8 font-bold leading-none justify-self-start">
        {formatTopBannerKind(banner.kind)}
      </div>

      <h2 className="font-bold text-sm truncate leading-tight">
        {banner.title}
      </h2>
    </article>
  );
};

export default TopBannerItem;
