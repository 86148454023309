import { MagazineResponse } from "@/web-client";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { FC } from "react";

interface Props {
  magazine: MagazineResponse;
}

const MagazineItem: FC<Props> = ({ magazine }) => {
  return (
    <article
      className={clsx(
        "grid grid-rows-[auto_auto_auto] gap-4",
        "group/magazine-item",
      )}
    >
      <h2 className="row-start-3 row-end-4 font-bold text-xs line-clamp-2">
        {magazine.title}
      </h2>
      <div className="row-start-2 row-end-3">
        <div className="flex items-center gap-8">
          <dl>
            <dt className="sr-only">記事の種類</dt>
            <dd className="rounded-full text-[8px] pt-4 pb-[3px] px-8 uppercase border leading-none">
              {magazine.post_type}
            </dd>
          </dl>

          <time className="text-xs" dateTime={magazine.date}>
            {magazine.date}
          </time>
        </div>
      </div>

      <div className="row-start-1 row-end-2 grid">
        <Link
          className="relative aspect-4/3 overflow-hidden rounded-xs"
          href={magazine.link}
          target="_blank"
        >
          <Image
            className={clsx(
              "object-cover object-center",
              "group-hover/magazine-item:brightness-90 transition-all",
            )}
            src={magazine.thumbnail_url}
            alt={magazine.title}
            fill
            unoptimized
          />
        </Link>
      </div>
    </article>
  );
};
export { MagazineItem };
