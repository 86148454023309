import { TopBannerKind } from "@/web-client";

export const formatTopBannerKind = (kind: TopBannerKind): string => {
  switch (kind) {
    case TopBannerKind.NEWS:
      return "NEWS";

    case TopBannerKind.TECTURE_MAGAZINE:
      return "TECTURE MAGAZINE";

    case TopBannerKind.GUIDE:
      return "GUIDE";

    case TopBannerKind.PROJECT:
      return "PROJECT";

    case TopBannerKind.POST:
      return "POST";

    case TopBannerKind.MATERIALS:
      return "MATERIALS";

    case TopBannerKind.EVENT:
      return "EVENT";
  }
};
