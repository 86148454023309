import { FC } from "react";
import { TopSectionPickupContentResponse } from "@/web-client";
import { SwiperSlide } from "swiper/react";
import BasicSwiper from "@/components/BasicSwiper";
import { useProjectsByIds } from "@/features/project/api/getProjectsByIds";
import PickupProjectItem from "@/features/project/components/PickupProjectItem";
import "swiper/css";
import { MaxWidthContainer } from "@/features/top_section/components/MaxWidthContainer";
import { LoadingPlaceholder } from "@/components/LoadingPlaceholder";
import useInViewOnce from "@/hooks/useInViewOnce";

interface Props {
  content: TopSectionPickupContentResponse;
  index: number;
}

const PickupProjectsSection: FC<Props> = ({ content, index }) => {
  const { inViewOnce, ref } = useInViewOnce();
  const { data: projects, error } = useProjectsByIds(
    inViewOnce ? `/sections/${content.id}/pickup_projects` : null,
    content.entities.map((e) => e.entity_id).join(","),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return (
    <MaxWidthContainer ref={ref}>
      {!projects ? (
        <LoadingPlaceholder
          className="aspect-[375/557] laptop:aspect-[1200/358]"
          error={!!error}
        />
      ) : (
        <BasicSwiper
          id={`pickup_projects_${index}`}
          swiperProps={{
            spaceBetween: 24,
            slidesPerGroup: 1,
            slidesPerView: 1,
            className: "small:!px-16",
            breakpoints: {
              // 800px以上の場合
              800: {
                slidesPerView: 1.72,
              },
            },
          }}
        >
          {projects.map((project) => (
            <SwiperSlide key={project.id}>
              <PickupProjectItem project={project} />
            </SwiperSlide>
          ))}
        </BasicSwiper>
      )}
    </MaxWidthContainer>
  );
};

export default PickupProjectsSection;
