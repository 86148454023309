import { FC, useCallback, useEffect, useState } from "react";
import { TopBannerResponse } from "@/web-client";
import TopBannerItem from "@/features/top_banner/components/TopBannerItem";
import IconButton from "@/components/IconButton";
import IconArrowUp from "@/assets/imgs/svg/icon_arrow_up.svg";
import IconArrowDown from "@/assets/imgs/svg/icon_arrow_down.svg";
import useScrollPosition from "@/hooks/useScrollPosition";

interface Props {
  banners: TopBannerResponse[];
}

const TopBanners: FC<Props> = ({ banners }) => {
  const { ref, scrollPosition } = useScrollPosition<HTMLDivElement>();

  const [isAtTop, setIsAtTop] = useState(true);
  const [isAtBottom, setIsAtBottom] = useState(false);

  const scrollTo = useCallback(
    (amount: number) => {
      if (ref.current) {
        ref.current.scrollBy({ top: amount, behavior: "smooth" });
      }
    },
    [ref],
  );

  const scrollUp = useCallback(() => {
    if (ref.current) {
      scrollTo(-ref.current.clientHeight);
    }
  }, [scrollTo, ref]);

  const scrollDown = useCallback(() => {
    if (ref.current) {
      scrollTo(ref.current.clientHeight);
    }
  }, [scrollTo, ref]);

  useEffect(() => {
    if (ref.current) {
      setIsAtTop(scrollPosition === 0);
      setIsAtBottom(
        ref.current.scrollHeight - scrollPosition === ref.current.clientHeight,
      );
    }
  }, [scrollPosition, ref]);

  return (
    <>
      {!isAtTop && (
        <div className="absolute top-0 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10">
          <IconButton
            size={"large"}
            onClick={scrollUp}
            aria-label=""
            rounded
            variant="secondary"
            className="shadow-md"
          >
            <IconArrowUp />
          </IconButton>
        </div>
      )}

      {!isAtBottom && (
        <div className="absolute bottom-0 left-1/2 translate-y-1/2 -translate-x-1/2 z-10">
          <IconButton
            size={"large"}
            onClick={scrollDown}
            aria-label=""
            rounded
            variant="secondary"
            className="shadow-md"
          >
            <IconArrowDown />
          </IconButton>
        </div>
      )}

      <div ref={ref} className="relative grid gap-24 overflow-scroll">
        {banners.map((banner) => (
          <TopBannerItem key={banner.id} banner={banner} />
        ))}
      </div>
    </>
  );
};
export default TopBanners;
