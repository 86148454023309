import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

interface InViewOnce {
  ref: (node?: Element) => void;
  inViewOnce: boolean;
}

const useInViewOnce = (rootMargin = "0px 0px 10% 0px"): InViewOnce => {
  const [inViewOnce, setInViewOnce] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true, // 一度だけトリガーする
    rootMargin, // デフォルトでは可視領域の下端を10%拡張して少し早めに発火するようにする
  });

  useEffect(() => {
    if (inView) {
      setInViewOnce(true);
    }
  }, [inView]);

  return { ref, inViewOnce };
};

export default useInViewOnce;
