import { TopSectionPickupContentResponse } from "@/web-client";
import { FC } from "react";
import "swiper/css";
import ListItem from "@/features/list/components/ListItem";
import { useGetListsByIds } from "@/features/list/api/getListsByIds";
import BasicSwiper from "@/components/BasicSwiper";
import { SwiperSlide } from "swiper/react";
import { MaxWidthContainer } from "@/features/top_section/components/MaxWidthContainer";
import { LoadingPlaceholder } from "@/components/LoadingPlaceholder";
import useInViewOnce from "@/hooks/useInViewOnce";

interface Props {
  content: TopSectionPickupContentResponse;
  index: number;
}

const PickupListsSection: FC<Props> = ({ content, index }) => {
  const { inViewOnce, ref } = useInViewOnce();
  const { data: lists, error } = useGetListsByIds(
    inViewOnce ? `/sections/${content.id}/pickup_lists` : null,
    content.entities.map((e) => e.entity_id).join(","),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return (
    <MaxWidthContainer ref={ref}>
      {!lists ? (
        <LoadingPlaceholder className="aspect-[1200/300]" error={!!error} />
      ) : (
        <BasicSwiper
          id={`pickup_lists_${index}`}
          swiperProps={{
            slidesPerView: 1.5,
            slidesPerGroup: 1,
            spaceBetween: 24,
            className: "small:!px-16",
            breakpoints: {
              960: {
                slidesPerView: 3,
                slidesPerGroup: 3,
              },
              1280: {
                slidesPerView: 4,
                slidesPerGroup: 4,
              },
            },
          }}
        >
          {lists.map((list) => (
            <SwiperSlide key={list.id}>
              <ListItem list={list} />
            </SwiperSlide>
          ))}
        </BasicSwiper>
      )}
    </MaxWidthContainer>
  );
};

export default PickupListsSection;
