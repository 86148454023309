import {
  DefaultApiProjectsSearchByIDsGetRequest,
  ProjectWithProductVariationsResponse,
} from "@/web-client/api";
import client from "@/utils/api/client";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import { AxiosResponse } from "axios";

export const getProjectsByIds = (
  request: DefaultApiProjectsSearchByIDsGetRequest,
): Promise<AxiosResponse<ProjectWithProductVariationsResponse[]>> =>
  client.projectsSearchByIDsGet(request);

export const useProjectsByIds = (
  key: string | null,
  ids: string,
  config?: SWRConfiguration,
): SWRResponse<ProjectWithProductVariationsResponse[]> => {
  const response = useSWR(
    key,
    async () => {
      const { data } = await getProjectsByIds({
        ids,
      });

      return data;
    },
    config,
  );

  return response;
};
