import { LoadingPlaceholder } from "@/components/LoadingPlaceholder";
import { MAGAZINE_URL } from "@/config/env";
import { useGetLatestArticles } from "@/features/magazine/api/getLatestArticles";
import { MagazineItem } from "@/features/magazine/components/MagazineItem";
import { MaxWidthContainer } from "@/features/top_section/components/MaxWidthContainer";
import BasicSwiper from "@/components/BasicSwiper";
import { TitleSection } from "@/features/top_section/components/TitleSection";
import useDevice from "@/hooks/useDevice";
import useInViewOnce from "@/hooks/useInViewOnce";
import clsx from "clsx";
import { FC } from "react";
import { SwiperSlide } from "swiper/react";

const LatestMagazinesSection: FC = () => {
  const { isSp } = useDevice();
  const { ref, inViewOnce } = useInViewOnce();

  const { data: articles, error } = useGetLatestArticles(
    inViewOnce ? `/latest_magazine_articles` : null,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return (
    <MaxWidthContainer ref={ref} wrapperClassName="bg-gray-50">
      {!articles ? (
        <LoadingPlaceholder className="aspect-[1200/274]" error={!!error} />
      ) : (
        <div className={clsx("py-16", "laptop:pt-16 laptop:pb-40")}>
          <div className="py-16">
            <TitleSection title={"TECTURE MAG"} link_url={MAGAZINE_URL} />
          </div>

          <BasicSwiper
            id="magazine"
            swiperProps={{
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: isSp ? 16 : 24,
              className: "small:!px-16",
              breakpoints: {
                960: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                },
                1280: {
                  slidesPerView: 4,
                  slidesPerGroup: 4,
                },
              },
            }}
          >
            {articles.map((article) => (
              <SwiperSlide key={article.id}>
                <MagazineItem magazine={article} />
              </SwiperSlide>
            ))}
          </BasicSwiper>
        </div>
      )}
    </MaxWidthContainer>
  );
};
export default LatestMagazinesSection;
