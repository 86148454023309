import { GetStaticProps, NextPage } from "next";
import { TopSettingsResponse } from "@/web-client/api";
import client from "@/utils/api/client";
import { NextSeo } from "next-seo";
import TopPage from "@/pages/TopPage";

interface Props {
  settigns: TopSettingsResponse;
}

const IndexPage: NextPage<Props> = ({ settigns }): JSX.Element => {
  return (
    <>
      <NextSeo></NextSeo>
      <TopPage settings={settigns} />
    </>
  );
};

export default IndexPage;

export const getStaticProps: GetStaticProps<Props> = async () => {
  const response = await client.topSettingsGet();

  return {
    props: {
      settigns: response.data,
    },
    revalidate: 86400,
  };
};
