import { TopSectionPickupContentResponse } from "@/web-client";
import { FC } from "react";
import "swiper/css";
import BoardItem from "@/features/board/components/BoardItem";
import { useGetBoardsByIds } from "@/features/board/api/getBoardsByIds";
import { MaxWidthContainer } from "@/features/top_section/components/MaxWidthContainer";
import { LoadingPlaceholder } from "@/components/LoadingPlaceholder";
import useInViewOnce from "@/hooks/useInViewOnce";
import clsx from "clsx";
import { SwiperSlide } from "swiper/react";
import BasicSwiper from "@/components/BasicSwiper";

interface Props {
  content: TopSectionPickupContentResponse;
  index: number;
}

const PickupBoardsSection: FC<Props> = ({ content, index }) => {
  const { inViewOnce, ref } = useInViewOnce();
  const { data: boards, error } = useGetBoardsByIds(
    inViewOnce ? `/sections/${content.id}/pickup_boards` : null,
    content.entities.map((e) => e.entity_id).join(","),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return (
    <MaxWidthContainer ref={ref}>
      {!boards ? (
        <LoadingPlaceholder
          className={clsx("aspect-[1200/163]")}
          error={!!error}
        />
      ) : (
        <BasicSwiper
          id={`pickup_boards_${index}`}
          swiperProps={{
            className: "small:!px-16",
            slidesPerView: 1,
            spaceBetween: 24,
            slidesPerGroup: 1,
            breakpoints: {
              640: {
                slidesPerView: 2,
              },
              960: {
                slidesPerView: 3,
              },
            },
          }}
        >
          {boards.map((board) => (
            <SwiperSlide key={board.id}>
              <BoardItem board={board} dark />
            </SwiperSlide>
          ))}
        </BasicSwiper>
      )}
    </MaxWidthContainer>
  );
};

export default PickupBoardsSection;
