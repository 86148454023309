import { FC, useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import Link from "next/link";
import { MaxWidthContainer } from "@/features/top_section/components/MaxWidthContainer";
import { useGetNews } from "@/features/news/api/getNews";
import { NewsEntity } from "@/web-client";
import { formatDate } from "@/utils/formatDate";
import { LoadingPlaceholder } from "@/components/LoadingPlaceholder";
import useInViewOnce from "@/hooks/useInViewOnce";
import clsx from "clsx";
import { formatNewsCategory } from "@/features/news";

const LatestNewsSection: FC = () => {
  const { ref, inViewOnce } = useInViewOnce();

  const { data, error } = useGetNews(
    inViewOnce ? "/latest_news" : null,
    {
      limit: 5,
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return (
    <MaxWidthContainer ref={ref} wrapperClassName="small:px-16">
      {data.length < 1 ? (
        <LoadingPlaceholder
          className="laptop:h-[50px] h-[72px]"
          error={!!error}
        />
      ) : (
        <div
          className={clsx(
            "bg-black text-white rounded-xs leading-none",
            "p-16",
            "laptop:px-24 laptop:py-16",
          )}
        >
          <Swiper
            modules={[Autoplay]}
            slidesPerView={1}
            spaceBetween={0}
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            loop
            centeredSlides
          >
            {data.map((value) => (
              <SwiperSlide key={value.title}>
                <NewsItem news={value} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </MaxWidthContainer>
  );
};

export { LatestNewsSection };

const NewsItem: FC<{ news: NewsEntity }> = ({ news }) => {
  const formattedDate = useMemo<string>(
    () => formatDate(news.published_at),
    [news],
  );

  return (
    <div
      className={clsx(
        "flex text-sm",
        "flex-col gap-8",
        "laptop:flex-row laptop:items-center laptop:gap-16",
      )}
    >
      <div className="flex items-center gap-16 text-sm">
        <time className="text-sm" dateTime={formattedDate}>
          {formattedDate}
        </time>

        <div className="border border-white rounded-full text-[8px] px-8 py-4 leading-none">
          {formatNewsCategory(news.category)}
        </div>
      </div>

      <div className="flex-1 flex items-center justify-between gap-16">
        <div className="line-clamp-1">
          {news.url ? (
            <Link className="hover:underline" href={news.url} target="_blank">
              {news.title}
            </Link>
          ) : (
            <>{news.title}</>
          )}
        </div>
      </div>
    </div>
  );
};
