import { useState, useEffect, useCallback, useRef } from "react";

const useScrollPosition = <T extends HTMLElement>() => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const ref = useRef<T>(null);

  const handleScroll = useCallback(() => {
    if (ref.current) {
      setScrollPosition(ref.current.scrollTop);
    }
  }, []);

  useEffect(() => {
    const element = ref.current;

    if (element) {
      element.addEventListener("scroll", handleScroll);
      // 初期スクロール位置を設定
      setScrollPosition(element.scrollTop);
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return { ref, scrollPosition };
};

export default useScrollPosition;
