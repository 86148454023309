import {
  BoardResponse,
  DefaultApiBoardsSearchByIDsGetRequest,
} from "@/web-client/api";
import client from "@/utils/api/client";
import { AxiosResponse } from "axios";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";

export const getBoardsByIds = (
  request: DefaultApiBoardsSearchByIDsGetRequest,
): Promise<AxiosResponse<BoardResponse[]>> =>
  client.boardsSearchByIDsGet(request);

export const useGetBoardsByIds = (
  key: string | null,
  ids: string,
  config?: SWRConfiguration,
): SWRResponse<BoardResponse[]> => {
  const response = useSWR(
    key,
    async () => {
      const { data } = await getBoardsByIds({
        ids,
      });

      return data;
    },
    config,
  );

  return response;
};
