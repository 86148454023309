import Link from "next/link";
import { FC } from "react";
import IconUpRight from "@/assets/imgs/svg/icon_arrow_up_right.svg";
import clsx from "clsx";
import { TopSectionTitleContentEntity } from "@/web-client";
import { useCheckInternalUrl } from "@/hooks/useCheckInternalUrl";

type Props = {
  titleClassName?: string;
} & Pick<TopSectionTitleContentEntity, "title" | "link_url">;

const TitleSection: FC<Props> = ({
  title,
  link_url,
  titleClassName = "laptop:text-2xl text-xl",
}) => {
  return (
    <div className="flex items-center justify-between gap-16 small:px-16">
      <h2 className={clsx("font-bold truncate leading-tight", titleClassName)}>
        {title}
      </h2>

      {link_url && <MoreLink url={link_url} />}
    </div>
  );
};

export { TitleSection };

interface MoreLinkProps {
  url: string;
}

const MoreLink: FC<MoreLinkProps> = ({ url }) => {
  const { isValid, internal, path } = useCheckInternalUrl(url);

  if (!isValid) return null;

  return (
    <div className="flex items-center gap-4 shrink-0">
      <Link
        className="underline text-sm"
        href={path}
        target={internal ? "_self" : "_blank"}
      >
        もっと見る
      </Link>
      <IconUpRight width={16} height={16} />
    </div>
  );
};
