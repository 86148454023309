import { useCallback, useEffect, useRef } from "react";

interface Interval {
  start: () => void;
  stop: () => void;
}

export const useInterval = (callback: () => void, delay: number) => {
  const intervalId = useRef<number | null>(null);
  const savedCallback = useRef<() => void>();

  // 保存されたコールバックを更新
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // インターバルを設定
  const start = useCallback(() => {
    stop();

    if (intervalId.current === null) {
      intervalId.current = window.setInterval(() => {
        if (savedCallback.current) {
          savedCallback.current();
        }
      }, delay);
    }
  }, [savedCallback, delay, intervalId]);

  // インターバルを停止
  const stop = useCallback(() => {
    if (intervalId.current !== null) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  }, [intervalId]);

  // コンポーネントがアンマウントされたときにインターバルをクリア
  useEffect(() => {
    return () => stop();
  }, []);

  return { start, stop };
};
