import { isInternalUrl } from "@/utils/isInternalUrl";
import { useMemo } from "react";

interface CheckInternalUrl {
  isValid: boolean;
  internal?: boolean;
  path?: string;
}

export const useCheckInternalUrl = (url: string): CheckInternalUrl => {
  // URLの検証
  const isValid = useMemo<boolean>(() => {
    try {
      new URL(url);

      return true;
    } catch {
      return false;
    }
  }, [url]);

  const internal = useMemo(() => {
    if (!isValid) return undefined;

    return isInternalUrl(url);
  }, [url, isValid]);

  const decodedUrl = useMemo(() => {
    return decodeURIComponent(url);
  }, [url]);

  const path = useMemo(() => {
    if (!isValid) return undefined;

    const url = new URL(decodedUrl);

    if (!internal) return url.toString();

    // internalならば、パスとクエリパラメータを含めた完全なパスを取得する
    return `${url.pathname}${url.search}${url.hash}`;
  }, [internal, isValid, decodedUrl]);

  return {
    isValid: isValid,
    path,
    internal,
  };
};
