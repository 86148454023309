import {
  DefaultApiListsSearchByIDsGetRequest,
  ListResponse,
} from "@/web-client/api";
import client from "@/utils/api/client";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import { AxiosResponse } from "axios";

export const getListsByIds = (
  request: DefaultApiListsSearchByIDsGetRequest,
): Promise<AxiosResponse<ListResponse[]>> =>
  client.listsSearchByIDsGet(request);

export const useGetListsByIds = (
  key: string,
  ids: string,
  config?: SWRConfiguration,
): SWRResponse<ListResponse[]> => {
  const response = useSWR(
    key,
    async () => {
      const { data } = await getListsByIds({
        ids,
      });

      return data;
    },
    config,
  );

  return response;
};
