import { PUBLIC_URL } from "@/config/env";

// 引数で渡したURLがサービス内のURLかどうかを判定する
export const isInternalUrl = (url: string): boolean => {
  const internalURL = new URL(PUBLIC_URL);
  const internalDomain = internalURL.hostname;

  // 渡されたURLが無効な形式である場合にエラーが発生する可能性があるため、try-catchで囲む
  try {
    const parsedUrl = new URL(url);

    return parsedUrl.hostname.endsWith(internalDomain);
  } catch {
    return false;
  }
};
