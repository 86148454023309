import client from "@/utils/api/client";
import { MagazineResponse } from "@/web-client";
import { AxiosResponse } from "axios";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";

export const getLatestArticles = (): Promise<
  AxiosResponse<MagazineResponse[]>
> => client.magazinesGet();

export const useGetLatestArticles = (
  key: string | null,
  config?: SWRConfiguration,
): SWRResponse<MagazineResponse[]> => {
  const response = useSWR(
    key,
    async () => {
      const { data } = await getLatestArticles();

      return data;
    },
    config,
  );

  return response;
};
