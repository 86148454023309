import { FC } from "react";
import Link from "next/link";
import IconArrowForward from "@/assets/imgs/svg/icon_arrow_forward.svg";
import { MaxWidthContainer } from "@/features/top_section/components/MaxWidthContainer";
import clsx from "clsx";

const MessageSection: FC = (): JSX.Element => {
  return (
    <MaxWidthContainer wrapperClassName="px-16 md:px-0">
      <section
        className={clsx(
          "rounded-xs text-white bg-[url('/imgs/top/bg_whats_tecture.png')] bg-cover bg-no-repeat bg-right-top",
          "p-16",
          "laptop:p-40",
        )}
      >
        <div className="grid gap-12 laptop:gap-24">
          <hgroup className="grid gap-8">
            <h2 className="font-bold text-base laptop:text-[36px]">
              TECTUREは、
              <br />
              すべての設計者のためのデータベースです。
            </h2>
            <p className="leading-loose text-xs laptop:text-base">
              設計者の複雑な業務フローをサポートし、
              <br />
              クリエイティブな環境を生み出すことを目指しています。
            </p>
          </hgroup>

          <div className="flex items-center gap-4">
            <Link
              className="underline font-bold text-xs laptop:text-base"
              href="/about"
            >
              くわしく見る
            </Link>

            <IconArrowForward width={16} height={16} className="fill-white" />
          </div>
        </div>
      </section>
    </MaxWidthContainer>
  );
};
export { MessageSection };
