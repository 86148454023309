import { PickupPostsViewer } from "@/features/top_section/components/PickupPostsViewer";
import { PostResponse } from "@/web-client";
import { FC, useCallback, useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import "swiper/css";
import clsx from "clsx";

interface Props {
  posts: PostResponse[];
  portalId?: string;
}

const PickupPostsViewers: FC<Props> = ({ posts, portalId }) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const completeHandler = useCallback(() => {
    if (!swiper) return;

    swiper.slideNext();
  }, [swiper]);

  const handleSlideChange = useCallback(() => {
    if (!swiper) return;

    setCurrentSlideIndex(swiper.realIndex);
  }, [swiper]);

  return (
    <div className={clsx("relative h-full w-full [&>*]:w-full [&>*]:h-full")}>
      <Swiper
        onSwiper={setSwiper}
        slidesPerView={1}
        onSlideChange={handleSlideChange}
        centeredSlides
        loop
        allowTouchMove={false}
      >
        {posts.map((post, index) => (
          <SwiperSlide key={post.id} className="[&>*]:w-full [&>*]:h-full">
            <PickupPostsViewer
              current={currentSlideIndex === index}
              post={post}
              onComplete={completeHandler}
              portalId={portalId}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* 吹き出しを描画するDOM */}
      {portalId && (
        <div
          className={clsx(
            "absolute inset-0 z-20",
            "pointer-events-none", // マウスホバーに反応しなくなるので親はマウスに反応しないようにする
            "[&>*]:pointer-events-auto", // 子はマウスに反応するようにする
          )}
          id={portalId}
        />
      )}
    </div>
  );
};
export { PickupPostsViewers };
