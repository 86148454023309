import usePagination, { PaginationResponse } from "@/hooks/usePagination";
import client from "@/utils/api/client";
import {
  DefaultApiNewsGetRequest,
  NewsEntity,
  NewsListResponse,
} from "@/web-client";
import { AxiosResponse } from "axios";
import { SWRConfiguration } from "swr";

export const getNews = (
  request: DefaultApiNewsGetRequest,
): Promise<AxiosResponse<NewsListResponse>> => client.newsGet(request);

export const useGetNews = (
  key: string,
  request: DefaultApiNewsGetRequest,
  config?: SWRConfiguration,
): PaginationResponse<NewsEntity> => {
  const pagination = usePagination<NewsEntity>(
    key,
    request.limit,
    async ({ limit, cursor }) => {
      const { data } = await getNews({
        limit,
        cursor: cursor as string,
      });

      return data;
    },
    { waitForAuth: true },
    config,
  );

  return pagination;
};
