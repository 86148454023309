import client from "@/utils/api/client";
import {
  DefaultApiTeamsSearchByIDsGetRequest,
  TeamResponse,
} from "@/web-client";
import { AxiosResponse } from "axios";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";

export const getTeamsById = async (
  param: DefaultApiTeamsSearchByIDsGetRequest,
): Promise<AxiosResponse<TeamResponse[]>> => client.teamsSearchByIDsGet(param);

export const useGetTeamsByIds = (
  key: string | null,
  ids: string,
  config?: SWRConfiguration,
): SWRResponse<TeamResponse[]> => {
  const response = useSWR(
    key,
    async () => {
      const { data } = await getTeamsById({
        ids,
      });

      return data;
    },
    config,
  );

  return response;
};
