import BasicSwiper from "@/components/BasicSwiper";
import { TopBannerResponse } from "@/web-client";
import { FC } from "react";
import { SwiperSlide } from "swiper/react";
import TopBannerItem from "@/features/top_banner/components/TopBannerItem";
import "swiper/css/pagination";

interface Props {
  banners: TopBannerResponse[];
}

const HorizontalTopBanners: FC<Props> = ({ banners }) => {
  return (
    <BasicSwiper
      id="banner_sp"
      swiperProps={{
        pagination: false,
        slidesPerView: 1.4,
        spaceBetween: 16,
        className: "small:!px-16",
      }}
    >
      {banners.map((banner, index) => (
        <SwiperSlide key={index}>
          <TopBannerItem banner={banner} />
        </SwiperSlide>
      ))}
    </BasicSwiper>
  );
};
export default HorizontalTopBanners;
