import { NewsCategory } from "@/web-client";

export const formatNewsCategory = (category: NewsCategory): string => {
  switch (category) {
    case NewsCategory.NEW:
      return "新着";

    case NewsCategory.ANNOUNCEMENT:
      return "お知らせ";

    case NewsCategory.NEW_FEATURE:
      return "新機能";

    case NewsCategory.EVENT:
      return "イベント";

    case NewsCategory.UPDATE:
      return "アップデート";
  }
};
